import { RBRecordDTO, ReportMetadataDTO } from '@/api/dto/report';
import { PropType } from 'vue';

export const reportProps = {
  report: {
    required: true,
    type: Object as PropType<ReportMetadataDTO>,
  },
  records: {
    required: true,
    type: Object as PropType<RBRecordDTO[]>,
  },
  isPrinting: {
    required: false,
    type: Boolean,
    default: false,
  }
};
