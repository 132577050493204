<template>
  <div class="report-weather-comp">
    <div v-if="weatherDataAvailable">
      <el-card class="box-card">
        <template #header class="flex-wrapper space-between vertical-center">
          <div>
            <h2>Weather</h2>
            <p>
              Data calculated on
              {{
                convertDateString(
                  new Date(weather.dt * 1000),
                  userPrefs?.timezone,
                )
              }}
            </p>
          </div>
          <!-- <div>
            <el-radio-group :value="mSystem" @input="mSystemChanged($event)">
              <el-radio-button label="imperial">Imperial</el-radio-button>
              <el-radio-button label="metric">Metric</el-radio-button>
            </el-radio-group>
          </div> -->
        </template>
        <div>
          <div class="flex-wrapper flex-centered vertical-center">
            <div>
              <img
                :src="
                  `http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`
                "
              />
            </div>
            <div>
              <h3>{{ weather.weather[0].main }}</h3>
            </div>
          </div>
          <div class="temp">
            <h1>
              {{
                formatTemp(weather.main.temp, userPrefs?.systemOfMeasurement)
              }}
            </h1>
            <span>Recorded Temperature</span>
          </div>
          <div class="vertical-margin flex-wrapper flex-wrap space-around">
            <div class="bordered-box flex-grow min-width">
              <h2>Locale</h2>
              <ul class="destyled-list">
                <li><strong>City:</strong> {{ weather.name }}</li>
                <li>
                  <strong>Coordinates:</strong> ({{ weather.coord.lat }},
                  {{ weather.coord.lon }})
                </li>
                <li>
                  <strong>Timezone Shift:</strong> UTC
                  <span v-if="weather.timezone >= 0">+</span
                  >{{ zeroPad(secsToHours(weather.timezone), 2) }}
                </li>
                <li>
                  <strong>Sunrise:</strong>
                  {{
                    convertDateString(
                      new Date(weather.sys.sunrise * 1000).toISOString(),
                      userPrefs?.timezone,
                    )
                  }}
                </li>
                <li>
                  <strong>Sunset:</strong>
                  {{
                    convertDateString(
                      new Date(weather.sys.sunset * 1000).toISOString(),
                      userPrefs?.timezone,
                    )
                  }}
                </li>
              </ul>
            </div>
            <div class="bordered-box flex-grow min-width">
              <h2>Details</h2>
              <ul class="destyled-list">
                <li>
                  <strong>Description:</strong>
                  {{ weather.weather[0].description }}
                </li>
                <li>
                  <strong>Recorded Temperature:</strong>
                  {{
                    formatTemp(
                      weather.main.temp,
                      userPrefs?.systemOfMeasurement,
                    )
                  }}
                </li>
                <li>
                  <strong>Feels Like:</strong>
                  {{
                    formatTemp(
                      weather.main.feels_like,
                      userPrefs?.systemOfMeasurement,
                    )
                  }}
                </li>
                <li>
                  <strong>Minimum Temperature:</strong>
                  {{
                    formatTemp(
                      weather.main.temp_min,
                      userPrefs?.systemOfMeasurement,
                    )
                  }}
                </li>
                <li>
                  <strong>Maximum Temperature:</strong>
                  {{
                    formatTemp(
                      weather.main.temp_max,
                      userPrefs?.systemOfMeasurement,
                    )
                  }}
                </li>
                <li><strong>Humidity:</strong> {{ weather.main.humidity }}%</li>
                <li>
                  <strong>Atmospheric Pressure:</strong>
                  {{ weather.main.pressure }} hPa
                </li>
                <li>
                  <strong>Wind Speed:</strong>
                  {{
                    formatSpeed(
                      weather.wind.speed,
                      userPrefs?.systemOfMeasurement,
                    )
                  }}
                </li>
                <li>
                  <strong>Wind Bearing:</strong> {{ weather.wind.deg }}&deg;
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p>
              This data was retrieved from
              <el-link
                type="primary"
                target="_blank"
                href="https://openweathermap.org"
                >OpenWeatherMap.org</el-link
              >.
            </p>
          </div>
        </div>
      </el-card>
    </div>
    <el-alert
      v-else
      title="Not Available"
      type="warning"
      :closable="false"
      description="There is no weather data available for this report."
      show-icon
    >
    </el-alert>
  </div>
</template>

<script>
// import dateFormat from '@/lib/date-format';
import { defineComponent, onMounted, ref } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';

const PRECISION = 100;
const kelvin2Fahrenheit = value =>
  // eslint-disable-next-line
  Math.round(((value - 273.15) * (9 / 5) + 32) * PRECISION) / PRECISION;
const kelvin2Celsius = value =>
  // eslint-disable-next-line
  Math.round((value - 273.15) * PRECISION) / PRECISION;
const mpsToMiphr = value => Math.round(value * 2.23694 * PRECISION) / PRECISION;
const secsToHours = value => value / 3600;

const zeroPad = (num, places) => {
  if (num < 0) {
    return `-${String(num * -1).padStart(places, '0')}`;
  }
  return String(num).padStart(places, '0');
};

export default defineComponent({
  name: 'ReportWeatherComp',
  props: {
    ...reportProps,
  },
  setup() {
    const userPrefs = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    return {
      userPrefs,
    };
  },
  computed: {
    weather() {
      return this.records ? this.records[0].data : undefined;
    },
    weatherDataAvailable() {
      try {
        return this.records !== undefined;
      } catch (e) {
        return false;
      }
    },
    orgId() {
      return this.$route.query.org_id;
    },
  },
  methods: {
    convertDateString,
    // mSystemChanged(event) {
    //   const newSettings = this.settings;
    //   newSettings.measurementSystem = event;
    //   this.$store.commit('reportDetail/SET_SETTINGS', newSettings);
    // },
    formatTemp(value, system) {
      // input should be in degrees Kelvin

      if (system === 'imperial') return `${kelvin2Fahrenheit(value)}\u00b0 F`;

      if (system === 'metric') return `${kelvin2Celsius(value)}\u00b0 C`;

      return value;
    },
    formatSpeed(value, system) {
      // input should be in meters per second

      if (system === 'imperial') return `${mpsToMiphr(value)} mi/hr`;

      if (system === 'metric') return `${value} m/s`;

      return value;
    },
    secsToHours,
    zeroPad,
  },
});
</script>

<style lang="scss">
.report-weather-comp {
  padding: 10px;

  .temp {
    text-align: center;

    h1 {
      font-size: 36pt;
      margin: 0;
    }

    span {
      font-size: 10pt;
    }
  }

  .min-width {
    min-width: 300px;
  }

  .box-card {
    max-width: 1000px;
    margin: auto;
  }

  .footer-btn {
    margin: 5px 10px;
    float: right;
  }
}
</style>
